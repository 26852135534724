import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { DanhMucXaPhuong } from '../shared/DanhMucXaPhuong.model';
import { DanhMucQuanHuyen } from '../shared/DanhMucQuanHuyen.model';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { ReportService } from 'src/app/shared/Report.service';

@Component({
  selector: 'app-dashboard000',
  templateUrl: './dashboard000.component.html',
  styleUrls: ['./dashboard000.component.css']
})
export class Dashboard000Component implements OnInit {

  isShowLoading: boolean = false;
  domainName = environment.DomainDestination;
  searchString: string = environment.InitializationString;
  DanhMucTinhThanhID: number = environment.DanhMucTinhThanhID;
  DanhMucQuanHuyenID: number = environment.InitializationNumber;
  DanhMucXaPhuongID: number = environment.InitializationNumber;


  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,


    public ReportService: ReportService,
  ) { }

  ngOnInit(): void {

    //this.DanhMucTinhThanhGetToList();

    //this.Report1001();
    //this.Report1002();
   // this.ReportThoiTiet0001();
    //this.ReportThoiTiet0002();

  }
  

  // Report1002() {
  //   this.isShowLoading = true;
  //   this.ReportService.Report000012ToListAsync().subscribe(
  //     res => {
  //       this.ReportService.list1002 = (res as Report[]);
  //       let labelArray = [];
  //       let labelArray001 = [];
  //       let labelArray002 = [];
  //       let dataArray001 = [];
  //       let dataArray002 = [];
  //       for (let i = 0; i < this.ReportService.list1002.length; i++) {
  //         labelArray.push(this.ReportService.list1002[i].DanhMucTinhThanhName);
  //         labelArray001.push(this.ReportService.list1002[i].DanhMucQuanHuyenName);
  //         labelArray002.push(this.ReportService.list1002[i].DanhMucXaPhuongName);
  //         dataArray001.push(this.ReportService.list1002[i].DienTichKhaoSat);
  //         dataArray002.push(this.ReportService.list1002[i].SoLuongKhaoSat);
  //       }
  //       let label001: string = 'Diện tích (ha)';
  //       let label002: string = 'Số lượng';
  //       this.ChartLabelsReport1002 = labelArray;
  //       this.ChartDataReport1002 = [
  //         { data: dataArray001, label: label001, stack: 'a', type: 'line', fill: false, yAxisID: 'A', },
  //         { data: dataArray002, label: label002, stack: 'b', yAxisID: 'B', }
  //       ];
  //       this.ChartLabelsReport1002SoLuongKhaoSat = labelArray001;
  //       this.ChartDataReport1002SoLuongKhaoSat = [
  //         { data: dataArray002, stack: 'a' },
  //       ];
  //       this.ChartLabelsReport1002DienTichKhaoSat = labelArray002;
  //       this.ChartDataReport1002DienTichKhaoSat = [
  //         { data: dataArray001, stack: 'a' },
  //       ];
  //       this.isShowLoading = false;
  //     },
  //     err => {
  //       this.isShowLoading = false;
  //     }
  //   );
  // }

  // Report1001() {
  //   this.isShowLoading = true;
  //   this.ReportService.Report00003ToListAsync().subscribe(
  //     res => {
  //       this.ReportService.FormData = (res as Report);
  //       this.isShowLoading = false;
  //     },
  //     err => {
  //       this.isShowLoading = false;
  //     }
  //   );
  // }

  public ChartOptionsReport1002: ChartOptions = {
    responsive: true,
    animation: {
      duration: 1,
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.fillStyle = "rgba(0, 0, 0, 1)";
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);

          });
        });
      }
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
          });
        }
      }
    },
    scales: {
      yAxes: [
        {
          id: 'A',
          position: 'left',
        }, {
          id: 'B',
          position: 'right',
        }
      ]
    },
  };
  public ChartColorsReport1002: Color[] = [
  ]
  public ChartLabelsReport1002: Label[] = [];
  public ChartTypeReport1002: ChartType = 'bar';
  public ChartLegendReport1002 = true;
  public ChartPluginsReport1002 = [];

  public ChartDataReport1002: ChartDataSets[] = [
  ];

  public ChartOptionsReport1002SoLuongKhaoSat: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + '';
        }
      }
    }
  };
  public ChartColorsReport1002SoLuongKhaoSat: Color[] = [
  ]
  public ChartLabelsReport1002SoLuongKhaoSat: Label[] = [];
  public ChartTypeReport1002SoLuongKhaoSat: ChartType = 'doughnut';
  public ChartLegendReport1002SoLuongKhaoSat = true;
  public ChartPluginsReport1002SoLuongKhaoSat = [];

  public ChartDataReport1002SoLuongKhaoSat: ChartDataSets[] = [
  ];

  public ChartOptionsReport1002DienTichKhaoSat: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + '';
        }
      }
    }
  };
  public ChartColorsReport1002DienTichKhaoSat: Color[] = [
  ]
  public ChartLabelsReport1002DienTichKhaoSat: Label[] = [];
  public ChartTypeReport1002DienTichKhaoSat: ChartType = 'doughnut';
  public ChartLegendReport1002DienTichKhaoSat = true;
  public ChartPluginsReport1002DienTichKhaoSat = [];

  public ChartDataReport1002DienTichKhaoSat: ChartDataSets[] = [
  ];


  Search() {
    alert("Đang phát triển");
  }

  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.List = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.List;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.BaseParameter.ParentID = this.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.List = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.List;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.BaseParameter.ParentID = this.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.List = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.List;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }

  public ChartOptionsReportThoiTiet0001NhietDo: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + '';
        }
      }
    }
  };
  public ChartColorsReportThoiTiet0001NhietDo: Color[] = [
  ]
  public ChartLabelsReportThoiTiet0001NhietDo: Label[] = [];
  public ChartTypeReportThoiTiet0001NhietDo: ChartType = 'line';
  public ChartLegendReportThoiTiet0001NhietDo = true;
  public ChartPluginsReportThoiTiet0001NhietDo = [];

  public ChartDataReportThoiTiet0001NhietDo: ChartDataSets[] = [
  ];

  public ChartOptionsReportThoiTiet0002NhietDo: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + '';
        }
      }
    }
  };
  public ChartColorsReportThoiTiet0002NhietDo: Color[] = [
  ]
  public ChartLabelsReportThoiTiet0002NhietDo: Label[] = [];
  public ChartTypeReportThoiTiet0002NhietDo: ChartType = 'line';
  public ChartLegendReportThoiTiet0002NhietDo = true;
  public ChartPluginsReportThoiTiet0002NhietDo = [];

  public ChartDataReportThoiTiet0002NhietDo: ChartDataSets[] = [
  ];

  public ChartOptionsReportThoiTiet0001DoAm: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + '';
        }
      }
    }
  };
  public ChartColorsReportThoiTiet0001DoAm: Color[] = [
  ]
  public ChartLabelsReportThoiTiet0001DoAm: Label[] = [];
  public ChartTypeReportThoiTiet0001DoAm: ChartType = 'line';
  public ChartLegendReportThoiTiet0001DoAm = true;
  public ChartPluginsReportThoiTiet0001DoAm = [];

  public ChartDataReportThoiTiet0001DoAm: ChartDataSets[] = [
  ];

  public ChartOptionsReportThoiTiet0002DoAm: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + '';
        }
      }
    }
  };
  public ChartColorsReportThoiTiet0002DoAm: Color[] = [
  ]
  public ChartLabelsReportThoiTiet0002DoAm: Label[] = [];
  public ChartTypeReportThoiTiet0002DoAm: ChartType = 'line';
  public ChartLegendReportThoiTiet0002DoAm = true;
  public ChartPluginsReportThoiTiet0002DoAm = [];

  public ChartDataReportThoiTiet0002DoAm: ChartDataSets[] = [
  ];

  // ReportThoiTiet0001() {
  //   this.isShowLoading = true;
  //   this.ReportService.ReportThoiTiet0001ToListAsync().subscribe(
  //     res => {
  //       this.ReportService.listThoiTiet0001 = (res as Report[]);
  //       let codeArray = [];
  //       let nameArray = [];
  //       for (let i = 0; i < this.ReportService.listThoiTiet0001.length; i++) {
  //         let code = codeArray.filter(item => item == this.ReportService.listThoiTiet0001[i].Code);
  //         if (code) {
  //           if (code.length == 0) {
  //             codeArray.push(this.ReportService.listThoiTiet0001[i].Code);
  //           }
  //         }
  //         let nameArraySub = nameArray.filter(item => item == this.ReportService.listThoiTiet0001[i].Name);
  //         if (nameArraySub) {
  //           if (nameArraySub.length == 0) {
  //             let name = this.ReportService.listThoiTiet0001[i].Name;

  //             nameArray.push(name);
  //             let dataArray= this.ReportService.listThoiTiet0001.filter(item => item.Name == name);
              
  //             let NhietDo = [];
  //             let DoAm = [];
  //             for (let j = 0; j < dataArray.length; j++) {
  //               NhietDo.push(dataArray[j].NhietDoCaoNhat);
  //               DoAm.push(dataArray[j].DoAmCaoNhat);
  //             }
  //             let dataNhietDo: any = {
  //               type: "line",
  //               fill: false,
  //               data: NhietDo,
  //               label: name,
  //               borderColor: this.DownloadService.GetRandomColor(NhietDo.length)
  //             }             
  //             let dataDoAm: any = {
  //               type: "line",
  //               fill: false,
  //               data: DoAm,
  //               label: name,
  //               borderColor: this.DownloadService.GetRandomColor(DoAm.length)
  //             }              
  //             this.ChartDataReportThoiTiet0001NhietDo.push(dataNhietDo);
  //             this.ChartDataReportThoiTiet0001DoAm.push(dataDoAm);
  //           }
  //         }
  //       }
  //       this.ChartLabelsReportThoiTiet0001NhietDo = codeArray;
  //       this.ChartLabelsReportThoiTiet0001DoAm = codeArray;
  //       this.isShowLoading = false;
  //     },
  //     err => {
  //       this.isShowLoading = false;
  //     }
  //   );
  // }
  // ReportThoiTiet0002() {
  //   this.isShowLoading = true;
  //   this.ReportService.ReportThoiTiet0002ToListAsync().subscribe(
  //     res => {
  //       this.ReportService.listThoiTiet0002 = (res as Report[]);
  //       let codeArray = [];
  //       let nameArray = [];
  //       for (let i = 0; i < this.ReportService.listThoiTiet0002.length; i++) {
  //         let code = codeArray.filter(item => item == this.ReportService.listThoiTiet0002[i].Code);
  //         if (code) {
  //           if (code.length == 0) {
  //             codeArray.push(this.ReportService.listThoiTiet0002[i].Code);
  //           }
  //         }
  //         let nameArraySub = nameArray.filter(item => item == this.ReportService.listThoiTiet0002[i].Name);
  //         if (nameArraySub) {
  //           if (nameArraySub.length == 0) {
  //             let name = this.ReportService.listThoiTiet0002[i].Name;

  //             nameArray.push(name);
  //             let dataArray= this.ReportService.listThoiTiet0002.filter(item => item.Name == name);
              
  //             let NhietDo = [];
  //             let DoAm = [];
  //             for (let j = 0; j < dataArray.length; j++) {
  //               NhietDo.push(dataArray[j].NhietDoCaoNhat);
  //               DoAm.push(dataArray[j].DoAmCaoNhat);
  //             }
  //             let dataNhietDo: any = {
  //               type: "line",
  //               fill: false,
  //               data: NhietDo,
  //               label: name,
  //               borderColor: this.DownloadService.GetRandomColor(NhietDo.length)
  //             }             
  //             let dataDoAm: any = {
  //               type: "line",
  //               fill: false,
  //               data: DoAm,
  //               label: name,
  //               borderColor: this.DownloadService.GetRandomColor(DoAm.length)
  //             }              
  //             this.ChartDataReportThoiTiet0002NhietDo.push(dataNhietDo);
  //             this.ChartDataReportThoiTiet0002DoAm.push(dataDoAm);              
  //           }
  //         }
  //       }
  //       this.ChartLabelsReportThoiTiet0002NhietDo = codeArray;
  //       this.ChartLabelsReportThoiTiet0002DoAm = codeArray;        
  //       this.isShowLoading = false;
  //     },
  //     err => {
  //       this.isShowLoading = false;
  //     }
  //   );
  // }
}
