<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-bar-chart fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Dashboard</h2>
            <h6 class="m-0 text-dark fw-light">
                Báo cáo thống kê
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
           
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4 justify-content-center align-items-center">
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-info">
                <div class="card-header">
                    <h5 class="card-title text-white">Số Lượng Hộ nuôi </h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">
                                <!-- {{ReportService.FormData.SoLuongThamGia |
                                number:
                                '1.0-0'}} -->
                                </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-danger">
                <div class="card-header">
                    <h5 class="card-title text-white">Số lượng Vùng Nuôi</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">
                                <!-- {{ReportService.FormData.SoLuongKhaoSat |
                                number:
                                '1.0-0'}} -->
                                </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-success">
                <div class="card-header">
                    <h5 class="card-title text-white">Tổng Diện tích Nuôi</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class=" text-white">
                            <h2 class="display-6 mb-2 fw-semibold">
                                 <!-- {{ReportService.FormData.DienTichThamGia |
                                number:
                                '1.0-0'}} -->
                                
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-warning">
                <div class="card-header">
                    <h5 class="card-title text-white">Tổng Số lượng loài</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">
                                <!-- {{ReportService.FormData.DienTichKhaoSat |
                                number:
                                '1.0-0'}} -->
                                
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-2 col-sm-12 col-12">
            <div class="card my-2 card-dark">
                <div class="card-header">
                    <h5 class="card-title text-white">Trạm Quang Trắc</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <div class="text-white">
                            <h2 class="display-6 mb-2 fw-semibold">
                                <!-- {{ReportService.FormData.DienTichKhaoSat |
                                number:
                                '1.0-0'}} -->
                                
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <br />
    <div class="row gx-4">
        <div class="col-lg-6 col-sm-12 col-12">
            <h3 class="text-center" style="text-transform: uppercase;">Số lượng và Diện tích Vùng Nuôi Nhuyễn Thể</h3>
            <canvas style="font-size: 30px;" baseChart [datasets]="ChartDataReport1002" [labels]="ChartLabelsReport1002"
                [chartType]="ChartTypeReport1002" [colors]="ChartColorsReport1002" [options]="ChartOptionsReport1002">
            </canvas>
        </div>
        <div class="col-lg-6 col-sm-12 col-12">
            <h3 class="text-center" style="text-transform: uppercase;">Số lượng và Diện tích Nuôi Thủy Sản</h3>
            <div class="table-responsive">
                <table class="table m-0 align-middle">
                    <thead>
                        <tr>
                            <th style="text-align: center;">STT</th>
                            <th style="text-align: center;">Loại thủy sản</th>
                            <th style="text-align: center;">Số lượng (dự tính)</th>
                            <th style="text-align: center;">Diện tích (ha)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <tr *ngFor="let item of ReportService.list1002; index as i;">
                            <td style="text-align: center;">
                                {{i + 1}}
                            </td>
                            <td>
                                <b>{{item.DanhMucTinhThanhName}}</b>
                            </td>
                            <td style="text-align: center;">
                                {{item.SoLuongKhaoSat |
                                number:
                                '1.0-0'}}
                            </td>
                            <td style="text-align: center;">
                                {{item.DienTichKhaoSat |
                                number:
                                '1.0-2'}}
                            </td>
                        </tr> -->
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <br />
    <div class="row gx-4">
        <div class="col-lg-6 col-sm-12 col-12">
            <h3 class="text-center" style="text-transform: uppercase;">DỰ BÁO NHIỆT ĐỘ</h3>
            <canvas baseChart [datasets]="ChartDataReportThoiTiet0001NhietDo"
                [labels]="ChartLabelsReportThoiTiet0001NhietDo" [chartType]="ChartTypeReportThoiTiet0001NhietDo"
                [colors]="ChartColorsReportThoiTiet0001NhietDo" [options]="ChartOptionsReportThoiTiet0001NhietDo">
            </canvas>
            <h3 class="text-center" style="text-transform: uppercase;">LỊCH SỬ NHIỆT ĐỘ</h3>
            <canvas baseChart [datasets]="ChartDataReportThoiTiet0002NhietDo"
                [labels]="ChartLabelsReportThoiTiet0002NhietDo" [chartType]="ChartTypeReportThoiTiet0002NhietDo"
                [colors]="ChartColorsReportThoiTiet0002NhietDo" [options]="ChartOptionsReportThoiTiet0002NhietDo">
            </canvas>
        </div>
        <div class="col-lg-6 col-sm-12 col-12">
            <h3 class="text-center" style="text-transform: uppercase;">DỰ BÁO ĐỘ ẨM</h3>
            <canvas baseChart [datasets]="ChartDataReportThoiTiet0001DoAm" [labels]="ChartLabelsReportThoiTiet0001DoAm"
                [chartType]="ChartTypeReportThoiTiet0001DoAm" [colors]="ChartColorsReportThoiTiet0001DoAm"
                [options]="ChartOptionsReportThoiTiet0001DoAm">
            </canvas>
            <h3 class="text-center" style="text-transform: uppercase;">LỊCH SỬ ĐỘ ẨM</h3>
            <canvas baseChart [datasets]="ChartDataReportThoiTiet0002DoAm" [labels]="ChartLabelsReportThoiTiet0002DoAm"
                [chartType]="ChartTypeReportThoiTiet0002DoAm" [colors]="ChartColorsReportThoiTiet0002DoAm"
                [options]="ChartOptionsReportThoiTiet0002DoAm">
            </canvas>
        </div>
    </div>
    <br />
   </div>
<br />	
<app-loading *ngIf="isShowLoading"></app-loading>